import Vue from 'vue'
import VueRouter from 'vue-router'

// Views
import LoginView from '../views/LoginView.vue'
import PdfGenerator from '../views/PdfGenerator.vue'
import ReferenceLineChecker from '../views/ReferenceLineChecker.vue'

// Import routes of wholesalers
import wholesalers from './wholesalers'

// Import routes of catalogue
import dashboard from './dashboard'

Vue.use(VueRouter)

const routes = [
  // login
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  // wholesalers and their children routes
  ...wholesalers,
  // dashboard and their children routes
  ...dashboard,
  {
    path: '/reference-line-checker',
    name: 'ReferenceLineChecker',
    component: ReferenceLineChecker
  },
  {
    path: '/PdfGenerator',
    name: 'PdfGenerator',
    component: PdfGenerator
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const loginPath = '/login'
  const publicPagesNames = [
    'Login',
    'NotFound',
    'ReferenceLineChecker',
    'PdfGenerator'
  ]
  const authRequired = !publicPagesNames.includes(to.name)

  const localStoreUser = localStorage.getItem('user')
  const user = localStoreUser ? JSON.parse(localStoreUser) : null

  if (authRequired && !user?.id) return next(loginPath)
  if (authRequired && user?.id) {
    const allowedRoutes = validateAllowAccess(user?.rol, user?.subrol)
    const routePath = to.path.split('/')[1]
    const allowRoute = allowedRoutes.includes(routePath)
    if (!allowRoute) {
      let fromPath = from.path.split('/')[1]
      fromPath = fromPath !== '' ? `/${fromPath}` : ''
      let firtsPath = allowedRoutes?.[0]
      firtsPath = firtsPath !== '' ? `/${firtsPath}` : ''

      const rederictPath = fromPath || firtsPath || loginPath
      return next(rederictPath)
    }
  }
  next()
})

const validateAllowAccess = (rol, subrol) => {
  const cataloguesRoutes = ['remaining-tickets', 'repeat-tickets', 'tickets-use', 'account-settings-dashboard', 'pos-configuration', 'users-configurations', 'manual-cancellations']

  const defaultRoutes = ['dashboard', 'reports', 'account-settings-dashboard'] // Cultur, Parador y aafy support
  const alLowedRoutesMap = {
    0: () => [...defaultRoutes, 'ticket-setup', ...cataloguesRoutes, 'API', 'wholesalers'], // Promtec
    1: () => {
      if (subrol === 3) return [...defaultRoutes, 'wholesalers'] // Wholesaler cashier
      return [...defaultRoutes, 'API', 'wholesalers'] // Aafy user
    },
    2: () => [...defaultRoutes], // Parador user
    4: () => {
      if (subrol === 0) return ['home', 'my-cart', 'account-settings', 'users', 'available-tickets'] // Wholesaler admin
      return ['home', 'my-cart', 'account-settings', 'available-tickets'] // wholesaler user
    },
    7: () => ['reports', 'account-settings-dashboard'], // accounting
    8: () => ['reports', 'API', 'ticket-setup', ...cataloguesRoutes] // helpdesk
  }

  return alLowedRoutesMap[rol] ? alLowedRoutesMap[rol]() : defaultRoutes
}

export default router
