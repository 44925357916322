<template>
  <div id="ReportByPlace">
    <TitleReportsCard
      title="Recaudación por parador"
      :loading="loading"
      @generateReport="generateReport"
    >
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateStart"
          :newMax="dateEnd"
          label="Seleccione una fecha de inicio"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <InputDatePickerReport
          v-model="dateEnd"
          label="Seleccione una fecha de fin"
          :disabled="loading"
          @change="generated = false"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <v-select
          v-model="placeSelected"
          prepend-icon="mdi-bank"
          dense
          outlined
          :items="placesForUser"
          name="id"
          item-text="name"
          item-value="id"
          label="Seleccione un parador"
          background-color="white"
          :disabled="loading"
          hide-details
          @change="generated = false"
        />
      </v-col>
    </TitleReportsCard>
    <ContentReportsCard
      :loading="loading"
      :generated="generated"
      :fileName="fileName"
      :noData="!usersDetails.length"
    >
      <ReportsCollectionsTableWholesalers
        v-if="placeSelected === 99"
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :usersDetails="usersDetails"
        :ticketsDetails="ticketsDetails"
        :totalPlace="totalPlace"
        :totalPlaceAtTickets="totalPlaceAtTickets"
        :placeId="placeSelected"
        :showComissions="rol === 2"
      />
      <ReportsCollectionsTable
        v-else
        :dateStart="dateStart"
        :dateEnd="dateEnd"
        :titleReport="getTitleReport"
        :usersDetails="usersDetails"
        :ticketsDetails="ticketsDetails"
        :totalPlace="totalPlace"
        :totalPlaceAtTickets="totalPlaceAtTickets"
        :receiptDetails="receiptDetails"
        :receiptTotal="receiptTotal"
        :fideA="fideA"
        :fideB="fideB"
        :anexos="anexos"
        :placeId="placeSelected"
        :showComissions="rol === 2"
      />
    </ContentReportsCard>
  </div>
</template>

<script>
// Mixins
import { mapGetters } from 'vuex/dist/vuex.common.js'
import ReportsCollectionsTableWholesalers from './ReportsCollectionsTableWholesalers.vue'
import COLLECTIONREPORTS from '../../minixs/collection-reports'

export default {
  name: 'ReportByPlace',
  components: {
    ReportsCollectionsTableWholesalers
  },
  mixins: [COLLECTIONREPORTS],
  data () {
    return {
      apiName: 'collectionByPlace',
      needPOS: false,
      needCashier: false
    }
  },
  computed: {
    getTitleReport: function () {
      const titleReport = `Recaudación de "${this.namePlaceSelected}"`
      return titleReport
    },
    fileName: function () {
      return 'Recaudación_de_' + this.namePlaceSelected + '_del_' + this.dateStart + '_hasta_' + this.dateEnd
    },
    ...mapGetters({
      userData: 'auth/userData'
    }),
    rol () {
      return this.userData.rol
    }
  }
}
</script>

<style>

</style>
