<template>
  <div id="ReportsCollectionsTable">
    <StyledTableReport
      :title="titleReport"
      :details="detailsTitle"
      :titleColspan="headersTicketsDetails.length"
      :headers="[]"
      :showTable="false"
      :showTotals="false"
      center
    />

    <StyledTableReport
      :headers="headersUserDetails"
      :showTotals="false"
    >
      <template v-for="(itemUserDetails, index1) in usersDetails">
        <tr
          :key="`spacer-total-user-${index1}-${itemUserDetails.sitio}`"
          class="spacer-styled-table"
        >
          <td
            colspan="3"
            class="text-center font-weight-bold"
          >
            {{ itemUserDetails.sucursal }}
          </td>
        </tr>
        <tr
          v-for="userTrasacctions in itemUserDetails.detalle"
          :key="`user-details-${userTrasacctions.IDCajero}-${userTrasacctions.Usuario}-${itemUserDetails.sucursal}`"
        >
          <td class="text-center">
            {{ userTrasacctions.IDCajero }}
          </td>
          <td>
            {{ userTrasacctions.Usuario }}
          </td>
          <td align="right">
            ${{ $formatPrice(userTrasacctions.total) }}
          </td>
        </tr>
        <tr :key="`total-details-user-${index1}`">
          <td
            colspan="2"
            align="right"
          >
            <strong>Total</strong>
          </td>
          <td align="right">
            <strong>${{ $formatPrice(itemUserDetails.total) }}</strong>
          </td>
        </tr>
      </template>
      <tr class="spacer-styled-table">
        <td colspan="3" />
      </tr>
      <tr>
        <td
          colspan="2"
          align="right"
        >
          <strong>Total recaudación</strong>
        </td>
        <td align="right">
          <strong>${{ $formatPrice(totalPlace) }}</strong>
        </td>
      </tr>
    </StyledTableReport>

    <StyledTableReport
      titleTable="Informe de Ventas"
      :headers="headersTicketsDetails"
      :showTotals="false"
      :total="getTotalCommision"
      :showIVA="showComissions"
      :includeSubtotal="true"
      :includeComission="showComissions"
      spacer
    >
      <template v-for="(itemTickets, index1) in ticketsDetails">
        <tr
          :key="`spacer-total-user-${index1}-${itemTickets.sitio}`"
          class="spacer-styled-table"
        >
          <td
            :colspan="headersTicketsDetails.length"
            class="text-center font-weight-bold"
          >
            {{ itemTickets.sucursal }}
          </td>
        </tr>
        <tr
          v-for="item in itemTickets.detalle"
          :key="`user-details-${item.persontype}-${item.npool}-${itemTickets.sucursal}`"
        >
          <td>{{ item.t_nombre }}</td>
          <td align="center">
            {{ $formatNum(item.t_Boletos) }}
          </td>
          <td align="right">
            ${{ $formatPrice(item.costo) }}
          </td>
          <td align="right">
            ${{ $formatPrice(item.total) }}
          </td>
          <td
            v-if="showComissions"
            align="right"
          >
            ${{ $formatPrice($getSubtotal(item.ComisionIndividual), decimalsOnReport) }}
          </td>
          <td
            v-if="showComissions"
            align="right"
          >
            ${{ $formatPrice($getSubtotal(item.Comision)) }}
          </td>
        </tr>
        <tr :key="`total-details-user-${index1}`">
          <td>
            <strong>Totales:</strong>
          </td>
          <td align="right">
            <strong>${{ $formatPrice(itemTickets.totalTickets) }}</strong>
          </td>
          <td align="right">
            <strong>Total</strong>
          </td>
          <td align="right">
            <strong>${{ $formatPrice(itemTickets.total) }}</strong>
          </td>
          <td align="right">
            <strong>Subtotal</strong>
          </td>
          <td align="right">
            <strong>${{ $formatPrice($getSubtotal(itemTickets.comission)) }}</strong>
          </td>
        </tr>
      </template>
    </StyledTableReport>
  </div>
</template>

<script>
import StyledTableReport from '../tools/reports/StyledTableReport.vue'

export default {
  name: 'ReportsCollectionsTableWholesalers',
  components: { StyledTableReport },
  props: [
    'dateStart',
    'dateEnd',
    'titleReport',
    'usersDetails',
    'ticketsDetails',
    'totalPlace',
    'totalPlaceAtTickets',
    'receiptTotal',
    'placeId',
    'showComissions'
  ],
  data () {
    return {
      decimalsOnReport: 6,
      detailsTitle: [
        {
          key: 'Fecha',
          value: `${this.$formatFecha(this.dateStart)} al ${this.$formatFecha(this.dateEnd)}`,
          attrs: { class: 'pt-2' }
        }
      ],
      headersUserDetails: [
        { name: 'ID cajero', attrs: {} },
        { name: 'Nombre del cajero', attrs: {} },
        { name: 'Importe', attrs: { width: '150px' } }
      ],
      headersTicketsBefore: [
        { name: 'Nombre del Boleto', attrs: { class: 'text-left' } },
        { name: 'Cantidad', attrs: {} },
        { name: 'Precio Unitario', attrs: {} },
        { name: 'Costo', attrs: {} }
      ]
    }
  },
  computed: {
    headersTicketsDetails: function () {
      const headers = this.headersTicketsBefore
      if (this.showComissions) {
        headers.push({ name: 'Comisión por boleto', attrs: {} })
        headers.push({ name: 'Comisión', attrs: { width: '150px' } })
      }
      return headers
    },
    getTotalCommision: function () {
      let total = 0
      this.ticketsDetails.forEach(function (item) {
        total += item.comission
      })
      return total
    }
  },
  methods: {
    FixCanje: (item = 0) => item
  }
}
</script>

<style scoped>

</style>
